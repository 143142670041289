<div class="header">
    <co2-tab-selector [tabsList]="subTabs" (selectTab)="onSelectTab($event)" [activeTab]="activeTab">
    </co2-tab-selector>

    <div class="flaring_content__switch form-switch-labels form-check form-switch">
        <label class="form-check-label"
            [ngClass]="flowRateView ? 'flaring_content__switch__not_selected' : 'flaring_content__switch__selected'"
            for="switchFlowRate">CO2 Emissions</label>
        <input class="form-check-input" type="checkbox" id="switchEmission" (change)="onChange($event)"
            [checked]="false">
        <label class="form-check-label"
            [ngClass]="flowRateView ? 'sankey_content__switch__selected' : 'sankey_content__switch__not_selected'"
            for="switchEmission">FlowRate</label>
    </div>
</div>

<co2-daily-flaring [display]="flowRateView" [type]="selectedTab" [fpso]="fpso"
    [affiliate]="affiliate"></co2-daily-flaring>

<co2-monthly-flaring [display]="flowRateView" [type]="selectedTab" [fpso]="fpso"
    [affiliate]="affiliate"></co2-monthly-flaring>