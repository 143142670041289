<div class="card">
  <div class="card-title">
    <span class="material-icons-round card-header-icon">local_fire_department&nbsp;</span>
    Daily flaring
  </div>

  <div class="card-body">
    <!-- TO BE ADDED IN ANOTHER US
      <co2-date-selection [quickSelection]=true >
    </co2-date-selection> -->
    <div *ngIf="!display">
      <div class="title">
        CO2 Emissions
      </div>
      <div class="header">
        <div class="subtitle">Historical</div>
        <div class="subtitle-hours">Last 72 hours</div>
      </div>

      <div class="co2-graph">
        <co2-ui-graph [options]="suctionDischargePressureGraphInputs"
          [noAvailableData]="noAvailableFlareHourly"></co2-ui-graph>
        <co2-ui-graph [options]="suctionDischargePressureGraphInputsHourly"
          [noAvailableData]="noAvailableFlareDaily"></co2-ui-graph>
      </div>
    </div>
    <div *ngIf="display">
      <div class="title">
        Flow Rate
      </div>
      <div class="header">
        <div class="subtitle">Historical</div>
        <div class="subtitle-hours">Last 72 hours</div>
      </div>

      <div class="co2-graph">
        <co2-ui-graph [options]="flowDailyInputs" [noAvailableData]="noAvailableFlowDaily"></co2-ui-graph>
        <co2-ui-graph [options]="flowHourlyInputs" [noAvailableData]="noAvailableFlowHourly"></co2-ui-graph>
      </div>
    </div>
  </div>
</div>